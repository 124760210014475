/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import "./header-big.scss";

interface Props {
  tag: string;
  span?: string;
  spanColor?: string;
  headerText?: string;
  className?: string;
  color?: string;
}

class HeaderBig extends React.Component {
  constructor(public props: Props) {
    super(props);
  }

  getHeader() {
    const content = (
      <>
        {this.props.span && (
          <span
            style={{ color: this.props.spanColor ? this.props.spanColor : "#ededed" }}
            dangerouslySetInnerHTML={{
              __html: this.props.span,
            }}
          ></span>
        )}
        {this.props.headerText && this.props.headerText}
      </>
    );
    switch (this.props.tag) {
      case "h2":
        return <h2 style={{ color: this.props.color ? this.props.color : "#000"}}>{content}</h2>;
        break;
      case "h3":
        return <h3 style={{ color: this.props.color ? this.props.color : "#000"}}>{content}</h3>;
        break;
      default:
        return <h2 style={{ color: this.props.color ? this.props.color : "#000"}}>{content}</h2>;
        break;
    }
  }
  render() {
    if (this.props) {
      let element = <div className={this.props.className ? 'header-big ' + this.props.className: 'header-big'}>{this.getHeader()}</div>;
      return element;
    }
  }
}

export default HeaderBig;
