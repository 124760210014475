import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import "./about.scss";

import CallFooter from "../../components/call-footer/call-footer";
import Customers from "../../components/customers/customers";
import Technologies from "../../components/technologies/technologies";
import BoxList from "../../components/box-list/box-list";
import HeaderBig from "../../components/header-big/header-big";
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";
import Section from "../../components/air-section/air-section";
import OfferShortcut from "../../components/offer-shortcut/offer-shortcut";
import ScrollButton from "../../components/scroll-button/scroll-button";

const ONasPage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const data = useStaticQuery(
    graphql`
      {
        onas: file(relativePath: { eq: "o-nas/o-nas.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  return (
    <>
      <SEO
        title="O nas"
        description="Agencja Interaktywna Aplinet powstała by tworzyć profesjonalne produkty cyfrowe oraz wspierać działania związane z marketingiem internetowym wszystkich firm z Poznania, Wielkopolski oraz całego kraju."
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>O nas</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Od 2013 roku zajmujemy się wprowadzaniem na rynek projektów
              informatycznych i&nbsp;outsourcingiem kadry IT
            </h2>
          </div>
          <h4>
            Jesteśmy wsparciem dla wsystkich firm, które szukają specjalistów
            z&nbsp;dziedziny marketingu online oraz dedykowanego oprogramowania.
          </h4>
        </div>
        <ScrollButton
          style={{
            position: "absolute",
            left: "50%",
            bottom: "5%",
            transform: "translateX(-50%)",
          }}
          to={scrollRef}
        />
      </Hero>

      <Section className="container" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Informacje"
          headerText="Co musisz wiedzieć o&nbsp;Agencji Interaktywnej Aplinet"
        />
      </Section>
      <Section>
        <div className="r :c-12 :c-6:sm+ :w">
          <div className="container">
            <HeaderBig
              tag="h3"
              span="01"
              headerText="Jesteśmy oryginalną, dynamiczną,&nbsp;doświadczoną Agencją
          Interaktywną i&nbsp;Software House'm"
            />
            <p>
              Zamiast kolejny raz zwracać uwagę na nasz profesjonalizm
              w&nbsp;dziedzinie tworzenia stron www, aplikacji, aplikacji
              mobilnych, wolimy podkreślić, nasze zaangażowanie w&nbsp;pracę
              z&nbsp;klientami dla stworzenia niepowtarzalnych projektów
              cyfrowych.
            </p>
            <p>
              Lubimy twórcze działanie, cenimy sobie dobry design i&nbsp;kochamy
              technologie. Znajomość aktualnych trendów i&nbsp;wieloletnie
              doświadczenie zespołu, przekładamy na realizację produktów, które
              nie tylko spełniają stawiane przed nimi oczekiwania, ale
              umożliwiają odniesienie sukcesu.
            </p>
            <p>
              <strong>
                Dzięki takiemu podejściu, każdego dnia stajemy się skuteczniejsi
                i&nbsp;lepsi w&nbsp;naszej pracy.
              </strong>
            </p>
            <blockquote>
              <p>
                Działamy na polskim rynku usług interaktywnych od 2013 roku,
                realizując kompleksowo projekty online oraz marketingowe.
              </p>
            </blockquote>
          </div>
          <div className="">
            <Img
              fluid={data.onas.childImageSharp.fluid}
              className="image-test"
              alt="Grafika przedstawia stronę internetową wyświetloną na urządzeniu Apple iPad"
              title="Mockup strony na tablecie"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </Section>
      <Section className="container-xs"></Section>
      <Section>
        <div className="container">
          <HeaderBig
            tag="h3"
            span="02"
            className="air-mb:2"
            headerText="Wspieramy w&nbsp;następujących obszarach"
          />
        </div>
        <OfferShortcut context={params.pageContext} />
        <div className="align-center air-mt:2">
          <Button
            color="primary"
            type="default"
            to="/oferta"
            language={language}
          >
            Przejdź na stronę oferty
          </Button>
        </div>
      </Section>

      <Section backgroundColor="black" className="container">
        <HeaderBig
          tag="h3"
          span="03"
          headerText="Realizujemy projekty kompleksowo"
          color="#50817b"
        />
        <p style={{ color: "#fff" }}>
          Od początku naszej działalności staramy się być firmą, która wzbudza
          zaufanie. Jesteśmy ambitni i&nbsp;potrzebne są nam wyzwania
          w&nbsp;których możemy się sprawdzać. Zależy nam na relacjach
          z&nbsp;klientami, dlatego misą firmy jest współpraca na zasadzie
          solidnych fundamentów.
        </p>
        <BoxList
          color="#fff"
          boxItems={[
            {
              title: `Zaangażowanie`,
              description:
                "Każdy realizowany projekt traktujemy wyjątkowo a&nbsp;satysfakcja z&nbsp;doskonale wykonanej pracy, motywuje cały nasz zespołu.",
            },
            {
              title: `Terminowość`,
              description:
                "Zdajemy sobie sprawę jak ważny jest czas realizacji zadania. Potrafimy skutecznie działać pod presją czasu i&nbsp;umiemy dowozić projekty w&nbsp;ustalonym terminie.",
            },
            {
              title: `Rzetelność`,
              description:
                "Jesteśmy precyzyjni, dokładni i&nbsp;poprawnie wykonujemy powierzone nam zadania. Wydajemy solidne, sprawdzone rozwiązanie.",
            },
            {
              title: `Elastyczność`,
              description:
                "Potrafimy przystosować się do zmieniającej się rzeczywistości: nowych technologii, innowacji, ewoluujących potrzeb klientów.",
            },
            {
              title: `Kreatywność`,
              description:
                "Tworzymy nowe rozwiązania a&nbsp;nasze pomysły potrafią zaspokoić potrzeby, uzyskać założony rezultat i&nbsp;rozwiązać problem.",
            },
            {
              title: `Lean management`,
              description:
                "Stawiamy na elastyczności, wysoką jakości, eliminowanie marnotrawstwa i&nbsp;ciągłe doskonalenie.",
            },
          ]}
        />
      </Section>
      <Section className="container">
        <div className="r :c-12 :c-6:sm+ :a-ce :w">
          <div>
            <HeaderBig
              tag="h3"
              span="04"
              headerText="Specjalizujemy się w&nbsp;najnowyszych technologiach"
            />
            <p>
              Realizujemy zlecenia w&nbsp;większości technologii webowych.
            </p>
          </div>
          <Technologies />
        </div>
      </Section>
      <Section backgroundColor="#000" className="container">
        <HeaderBig
          tag="h2"
          span="Nasze największe Atuty"
          headerText="Prawdziwy sukces nie jest kwestią przypadku"
          color="#50817b"
        />
        {/*
         */}
        <BoxList
          boxItems={[
            {
              title: `Profesjonalny zespół`,
              description:
                "Doskonale rozumiemy potrzeby klientów, dlatego profesjonalne, rzetelne i&nbsp;systematyczne wykonywanie swoje obowiązki.",
            },
            {
              title: `Doświadczeni specjaliści`,
              description:
                "Nasze kompetencje pozwalają tworzyć spójne, zaplanowane realizacje.",
            },
            {
              title: `Konkurencyjność`,
              description:
                "Gwarantujemy bardzo dobry stosunek ceny do jakości oferowanych usług.",
            },
            {
              title: `Dokładność`,
              description:
                "Dbamy o szczegóły i&nbsp;poprawność działania wszystkich produktów.",
            },
            {
              title: `Szybkość reakcji`,
              description:
                "Jesteśmy dostępni i&nbsp;potrafimy reagować na zadania w&nbsp;błyskawicznym tempie.",
            },
            {
              title: `Kompleksowość`,
              description:
                "Posiadamy specjalistów z&nbsp;różnymi umiejętnościami, dlatego strony internetowe, sklepy oraz aplikacje nie mają przed nami tajemnic.",
            },
          ]}
        />
        <div style={{ textAlign: "center" }} className="air-mt:2">
          <Button color="primary" type="inversed" to="/oferta/" language="pl">
            Sprawdź naszą ofertę
          </Button>
        </div>
      </Section>
      <Section className="container">
        <HeaderBig tag="h2" span="Partnerzy" headerText="Nasi Klienci" />
        <h4>
          To właśnie Oni są naszą najlepszą wizytówką i&nbsp;powodem do dumy...
          Dziękujemy za Wasze zaufanie i&nbsp;rekomendacje!
        </h4>
      </Section>
      <Customers />
      <Section>
        <div className="align-center air-mt:2">
          <Button
            color="primary"
            type="default"
            to="/klienci"
            language={language}
          >
            Zobacz dla kogo pracowaliśmy
          </Button>
        </div>
      </Section>
      {/* <Brands /> */}
      <CallFooter />
    </>
  );
};

export default ONasPage;
