import React, { ReactNode } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { rhythm, scale } from "../../utils/typography";

import Img from "gatsby-image";

import LogoAngular from "../../images/technologies/logo_angular.inline.svg";
import LogoAngularJs from "../../images/technologies/logo_angularjs.inline.svg";
import LogoElectron from "../../images/technologies/logo_electron.inline.svg";
import Ionic from "../../images/technologies/logo_ionic.inline.svg";
import Magento from "../../images/technologies/logo_magento.inline.svg";
import MySql from "../../images/technologies/logo_mysql.inline.svg";
import NativeScript from "../../images/technologies/logo_nativescript.inline.svg";
import NodeJs from "../../images/technologies/logo_nodejs.inline.svg";
import OpenCart from "../../images/technologies/logo_opencart.inline.svg";
import Php from "../../images/technologies/logo_php.inline.svg";
import PostgreSql from "../../images/technologies/logo_postgresql.inline.svg";
import Slim from "../../images/technologies/logo_slim.inline.svg";
import Flutter from "../../images/technologies/logo-flutter.inline.svg";
import ReactLogo from "../../images/technologies/logo-react.inline.svg";
import VueJs from "../../images/technologies/logo-vuejs.inline.svg";
import Laravel from "../../images/technologies/logo-laravel.inline.svg";
import Woocommerce from "../../images/technologies/logo_woocommerce.inline.svg";
import PrestaShop from "../../images/technologies/logo_prestashop.inline.svg";

import "./technologies.scss";

interface Props {}

class Technologies extends React.Component {
  constructor(public props: Props) {
    super(props);
  }

  techItems = [
    {
      name: "LogoAngular",
      src: <LogoAngular title="Logo technologii Angular" />,
    },
    {
      name: "LogoAngularJs",
      src: <LogoAngularJs title="Logo technologii AngularJS" />,
    },
    {
      name: "ReactLogo",
      src: <ReactLogo title="Logo technologii React"/>,
    },
    {
      name: "VueJS",
      src: <VueJs title="Logo technologii VueJs"/>,
    },
    {
      name: "NodeJs",
      src: <NodeJs title="Logo technologii NodeJs" />,
    },
    {
      name: "Flutter",
      src: <Flutter title="Logo technologii Flutter"/>,
    },
    {
      name: "NativeScript",
      src: <NativeScript title="Logo technologii NativeScript" />,
    },
    {
      name: "LogoElectron",
      src: <LogoElectron title="Logo technologii LogoElectron" />,
    },
    {
      name: "Ionic",
      src: <Ionic title="Logo technologii Ionic" />,
    },
    {
      name: "Laravel",
      src: <Laravel title="Logo technologii Laravel" />,
    },
    {
      name: "Php",
      src: <Php title="Logo technologii Php" />,
    },
    {
      name: "Slim",
      src: <Slim title="Logo technologii Slim" />,
    },
    {
      name: "PostgreSql",
      src: <PostgreSql title="Logo technologii PostgreSql" />,
    },
    {
      name: "MySql",
      src: <MySql title="Logo technologii MySql" />,
    },
    {
      name: "Magento",
      src: <Magento title="Logo technologii Magento" />,
    },
    {
      name: "OpenCart",
      src: <OpenCart title="Logo technologii OpenCart" />,
    },
    {
      name: "Woocommerce",
      src: <Woocommerce title="Logo technologii Woocommerce" />,
    },
    {
      name: "PrestaShop",
      src: <PrestaShop title="Logo technologii PrestaShop" />,
    },
  ];

  render() {
    return (
      <div className="technologies-wrapper">
        <ul className="r :grow :c-3 :c-4:md- :c-6:xs- :w :a-ce ">
          {this.techItems.map((item, index) => {
            return (
              <li key={index} className="r :c-12 :a-cc ">
                <div className="wrapper">{item.src}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Technologies;
